.table > tbody > tr > td {
  vertical-align: bottom;
  font-weight: bold;
}

.nav > li > a {
  padding: 5px 5px;
}

.form-control-feedback {
  margin-top: 6px;
}

/*checkbox material*/

.material-switch > input[type="checkbox"] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}

label.switch {
  margin-bottom: 20px;
  margin-top: 20px;
}

.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}

.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}

.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}

.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}

.file-upload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}

.file-upload input.file-input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

td.derecha, th.derecha {
  text-align: right;
}

td.centrado, th.centrado {
  text-align: center;
}

td.izquierda, th.izquierda {
  text-align: left;
}

.font-bold {
  font-weight: bold;
  font-size: 14px;
}

div[ng-app] {
  margin: 50px;
}

label {
  display: block;
}

.valor_aplicar_blanco {
  background-color: #fff;
}

.valor_aplicar_yellow {
  background-color: lightyellow;
  font-size: 16px;
  font-weight: bold;
}

.panel-group {
  margin-bottom: 5px;
}

/*MIS ICONOS*/

.glyphicon-reset-password {
  content: url('/assets/images/change_password.png');
}

.glyphicon-permisos {
  content: url('/assets/images/folder_user.png');
}

.glyphicon-60 {
  content: url('/assets/images/60.png');
}
.glyphicon-busy {
  content: url('/assets/images/busy.gif');
}

.huge {
  font-size: 20px;
  font-weight: bolder;
}

.table-condensed {
  padding: 10px;
}

.scroll {
  max-height: 480px;
  overflow-y: scroll;
}

.selected {
  font-weight: bold;
  color: #d9534f;
}
